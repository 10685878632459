import React from "react"
import { Image, Row, Col } from "react-bootstrap"
// illustration
import UserInMonitor from "../../../static/images/UserInMonitor.svg"
// styles
import "./styles.scss"

const index = ({ title, description, direction }) => {
  const orientation = direction === "reverse" ? "row-reverse" : "row"
  return (
    <Row className="monitoring-row p-3" style={{ flexDirection: orientation }}>
      <Col className="mx-auto">
        <p className="mx-auto display-5 weight-800">{title}</p>
        <p className="mx-auto">{description}</p>
      </Col>
      <Col className="mx-auto">
        <Image
          src={UserInMonitor}
          rounded
          alt="user sitting looking to a computer screen monitoring"
        />
      </Col>
    </Row>
  )
}

export default index
