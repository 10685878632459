import React from "react"
import { Container } from "react-bootstrap"
import GroupTherapy from "../../../static/images/group-therapy.svg"
import OnlineConnection from "../../../static/images/online-connection.svg"
import PersonalData from "../../../static/images/personal-data.svg"
import AioTools from "../../components/aioTools"
import Connection from "../../components/connections"
import Footer from "../../components/footer"
import HeroAlt from "../../components/heroAlt"
import Ideas from "../../components/ideas"
import Layout from "../../components/layout"
import LogoCloud from "../../components/LogoClouds"
import Monitoring from "../../components/monitoring"
import NavBar from "../../components/navBar"
import Seo from "../../components/seo"
import SolutionNav from "../../components/SolutionNav"

const Solutions = ({ location }) => {
  return (
    <Layout>
      <Seo title="Team Coaches" />
      <NavBar location={location} />
      <Container>
        <SolutionNav />
        <HeroAlt
          title="Community Software for innovation programs"
          description="Team Coaches is the next level of your community and operations for accelerators, incubators, startup studios and enterprises."
        />
        <LogoCloud />
        <AioTools
          title="All your tools in one place"
          description="All the tools you need – from mentor matching and digital curriculum to CRM and room bookings – together in a single platform, tailor made for startup communities."
          solution={true}
          imageSource={PersonalData}
          direction="row-reverse"
        />
        <Connection
          title="Maximize the value of your network"
          description="Make the right connections at the right time between your founders, mentors, investors, and partners"
          imageSource={OnlineConnection}
          direction="reverse"
        />
        <Ideas
          title="Provide personalized experiences to your community"
          description="Give each member of your community a personalized experience based on their needs, interests, and performance"
          imageSource={GroupTherapy}
        />
        {/* <Cta backgroundColor="back-gray" buttonColor="gray" /> */}
        <Monitoring
          title="Gain a complete view of what’s happening across your program"
          description="Use your community dashboard to understand scheduling, engagement, and performance with a single glance"
        />
        <Footer />
      </Container>
    </Layout>
  )
}

export default Solutions
