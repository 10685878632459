import PropTypes from "prop-types"
import React, { useContext } from "react"
import { Button, Col, Row } from "react-bootstrap"
import { ModalContext } from "../layout"
import "./styles.scss"

const Index = ({ title, description }) => {
  const [, setShowModal] = useContext(ModalContext)
  const handleShow = () => setShowModal(true)
  return (
    <Row
      fluid="md"
      className="m-0 justify-content-center justify-items-center align-items-center hero-alt"
      style={{ minHeight: "20rem" }}
    >
      <Col fluid className="px-3 text-center hero-alt-text" xs={12}>
        <h1 className="h1">{title}</h1>
        <p className="py-2">{description}</p>
        <Button className="mx-auto" onClick={handleShow}>
          Book a Demo
        </Button>
      </Col>
    </Row>
  )
}

Index.propTypes = {
  siteTitle: PropTypes.string,
}

Index.defaultProps = {
  siteTitle: ``,
}

export default Index
